@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;700;900&display=swap');

@layer base {
  html {
    font-family: Inter, system-ui, sans-serif;
  }
}

body {
  margin: 0;
  /* font-family: 'Commissioner', 'Raleway', -apple-system, BlinkMacSystemFont, 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*:focus-visible,
input:focus-visible,
button:focus-visible {
  outline: 1px solid #ff9e6e40;
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}

button {
  cursor: pointer;
  outline: 2px solid transparent;
  outline-offset: 2px;
}

input {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

@layer components {
  [type='checkbox']:checked {
    background-image: url("data:image/svg+xml,%3Csvg width='14' height='10' viewBox='0 0 14 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.2673 1.13329C11.6881 0.715987 12.3703 0.715987 12.7912 1.13329C13.212 1.55059 13.212 2.22717 12.7912 2.64448L6.32586 9.0559C5.90505 9.4732 5.22278 9.4732 4.80197 9.0559L1.21014 5.494C0.789329 5.07669 0.789329 4.40011 1.21014 3.98281C1.63095 3.56551 2.31321 3.56551 2.73402 3.98281L5.56391 6.78912L11.2673 1.13329Z' fill='%23F15836'/%3E%3C/svg%3E%0A");
  }

  [type='radio']:checked {
    background-image: url("data:image/svg+xml,%3Csvg width='20' height='21' viewBox='0 0 20 21' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='0.75' y='0.789062' width='18.5' height='18.5' rx='9.25' fill='%23FFC7BB' stroke='%23F15836' stroke-width='1.5'/%3E%3Crect x='3.80469' y='3.8418' width='12.394' height='12.394' rx='6.19699' fill='%23F15836'/%3E%3C/svg%3E%0A");
  }

  [type='checkbox'],
  [type='radio'] {
    @apply focus:ring-0 focus:ring-offset-0;
  }
}

.rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
  box-shadow: 0 0 0 5px #f158364d !important;
}

body.modal-open {
  overflow: hidden;
  position: fixed;
}
